import React, { useEffect} from 'react';
import './App.css';
import { Routes, Route, BrowserRouter, useParams } from "react-router-dom";
import { Home } from './Home';



function App() {
    
    return (
      <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/:id" element={<Redirect />} />      
       </Routes>
      </BrowserRouter>
  );
}

function Redirect() {

    let { id } = useParams();
    
    useEffect(() => {
        if (id !== undefined && id !== '') {
            window.location.replace('/api/ShortenedUrlRedirect/' + id);
        }
    }, [id])


    return null;
}




export default App;

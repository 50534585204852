import React, { useEffect} from 'react';

export const Home = () => {
    useEffect(() => {
       
            window.location.replace('https://www.grandmh.com');
    }, [])


    return null;
    
}